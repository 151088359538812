<template>
  <div>
    <b-modal v-model="imageOpen" scrollable centered size="xl" @hide="checkUploadData()"
      :header-class="['py-2', 'bg-dagee']" :body-class="['p-0']"
      :footer-class="['p-0', 'bg-white']">

      <template v-slot:modal-header="{ close }">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill">
            <b>{{pointData.name}}</b>
          </div>
          <div @click="close()">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </div>
        </div>
      </template>

      <template v-slot:default='{ close }'>
        <div class="w-100 p-2">
          <table class="table table-sm">
            <thead>
              <tr>
                <th class="text-center">No</th>
                <th class="text-center">圖片</th>
                <th class="text-center">名稱</th>
                <th class="text-center">來源</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(image, index) in imageTemp" :key="'my__image__' + index">
                <td class="text-center" style="vertical-align: middle">
                  {{index + 1}}
                </td>
                <td class="align-items-center align-content-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <img :src="getRealyUrl(image.image)" width="80" height="auto" />
                  </div>
                </td>
                <td style="vertical-align: middle">
                  {{image.name}}
                </td>
                <td style="vertical-align: middle">
                  {{image.source}}
                </td>
              </tr>
              <tr v-if="imageList.length > 0">
                <td class="bg-light text-danger text-center" colspan="4">
                  <b>尚未上傳</b>
                </td>
              </tr>
              <tr v-for="(image, index) in imageList" :key="'image__' + index">
                <td class="text-center" style="vertical-align: middle">
                  {{index + 1}}
                </td>
                <td class="align-items-center align-content-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <img :src="image.files[0].thumb" width="80" height="auto"
                      v-if="image.files.length > 0" />
                    <img :src="image.image" width="80" height="auto" v-else />
                  </div>
                </td>
                <td style="vertical-align: middle">
                  {{image.name}}
                </td>
                <td style="vertical-align: middle">
                  {{image.source}}
                </td>
              </tr>

              <tr>
                <td class="text-center" colspan="4">
                  <div class="d-flex align-items-center justify-content-center pt-2">
                    <button style="width: 36px; height: 36px" @click="imageUpload = !imageUpload"
                      class="btn btn-sm text-secondary d-flex align-items-center justify-content-center border rounded-circle">
                      <font-awesome-icon :icon="['fas', 'plus']" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <div class="d-flex align-items-center justify-content-center">
            <file-upload class="btn btn-sm btn-primary" v-model="imageTemp" ref="uploadImage"
              extensions="gif, jpg, jpeg, png" accept="image/png, image/gif, image/jpeg, image/jpg"
              @input-filter="inputFilter" @input-file="inputFile">
              選擇
            </file-upload>
          </div> -->
        </div>
      </template>

      <template v-slot:modal-footer='{close}'>
        <div class="w-100 d-flex align-content-center justify-content-around">
          <button class="btn btn-sm bg-dagee text-white px-4" @click="upload()">
            <b>上傳</b>
          </button>

          <button class="btn btn-sm btn-danger px-4" @click="close">
            <b>關閉</b>
          </button>
        </div>
      </template>
    </b-modal>

    <b-modal ref="input_new_image" v-model="imageUpload" scrollable centered size="xl"
      @hide="checkImageUpload()" v-if="imageUpload" :header-class="['py-2', 'bg-dagee']"
      :body-class="['p-0']" :footer-class="['p-0', 'bg-white']">
      <template v-slot:modal-header="{ close }">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill">
            <b>新增圖片</b>
          </div>
          <div @click="close()">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </div>
        </div>
      </template>

      <template v-slot:default='{close}'>
        <div class="w-100 d-flex align-items-center justify-content-center p-2">
          <div class="w-100" v-show="!edit">
            <div class="d-flex flex-column">
              <div class="w-100 d-flex align-items-center justify-content-center">
                <img :src="uploadTemp.files[0].thumb" width="200" height="auto"
                  v-if="uploadTemp.files.length > 0" />
                <img :src="uploadTemp.image" width="200" height="auto" v-else />
              </div>

              <b-form-group label="圖片" label-for="input-image">
                <div class="d-flex align-items-center justify-content-center">
                  <div style="min-width: 60px">
                    <file-upload class="btn btn-sm btn-primary" v-model="uploadTemp.files"
                      ref="uploadImage" extensions="gif, jpg, jpeg, png"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      @input-filter="inputFilter" @input-file="inputFile">
                      選擇
                    </file-upload>
                  </div>
                  <b-form-input id="input-image" class="px-2" v-model="uploadTemp.image" trim
                    placeholder="請選擇圖片或輸入圖片網址">
                  </b-form-input>

                </div>
              </b-form-group>

              <b-form-group label="圖片名稱" label-for="input-image-name">
                <b-form-input id="input-image-name" class="px-2" v-model="uploadTemp.name" trim
                  :invalid-feedback="invalidFeedback(uploadTemp.name)"
                  :state="state(uploadTemp.name)" placeholder="請選擇圖片名稱">
                </b-form-input>
              </b-form-group>

              <b-form-group label="圖片來源" label-for="input-image-source">
                <b-form-input id="input-image-source" class="px-2" v-model="uploadTemp.source" trim
                  :invalid-feedback="invalidFeedback(uploadTemp.source)"
                  :state="state(uploadTemp.source)" placeholder="請輸入來源">
                </b-form-input>
              </b-form-group>

              <div class="d-flex align-items-center justify-content-center">
                <div>
                  {{$store.state.user.Login.name}}
                </div>
              </div>

              <!-- <div class="w-100 d-flex align-items-center justify-content-center">
                <file-upload class="btn btn-sm btn-primary" v-model="imageTemp" ref="uploadImage"
                  extensions="gif, jpg, jpeg, png"
                  accept="image/png, image/gif, image/jpeg, image/jpg" @input-filter="inputFilter"
                  @input-file="inputFile">
                  選擇
                </file-upload>
              </div> -->
            </div>
          </div>

          <div v-show="uploadTemp.files.length && edit">
            <div class="cropper-first">
              <div class="avatar-edit-image" v-if="uploadTemp.files.length">
                <img ref="editImage" :src="uploadTemp.files[0].url" style="max-width: 100%;">
              </div>

              <div class="w-100 py-2">
                <div class="d-flex align-items-center justify-content-center">
                  {{degree}} &deg;
                </div>
                <div class="d-flex align-items-center justify-content-around">
                  <button class="btn btn-outline-primary btn-sm" @click="changeRotate(90)">
                    <font-awesome-icon :icon="['fas', 'redo-alt']" /> 90 &deg;
                  </button>
                  <button class="btn btn-outline-primary btn-sm" @click="changeRotate(1)">
                    +1 &deg;
                  </button>
                  <button class="btn btn-outline-primary btn-sm" @click="changeRotate(-1)">
                    -1 &deg;
                  </button>
                  <button class="btn btn-outline-success btn-sm" @click="editSave()">
                    裁剪
                  </button>
                  <button class="btn btn-outline-danger btn-sm" @click="editCancel()">
                    取消
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer='{close}'>
        <div class="w-100 d-flex align-content-center justify-content-around">
          <button class="btn btn-sm bg-dagee text-white px-4" @click="uploadSubmit(close)">
            <b>確定</b>
          </button>

          <button class="btn btn-sm btn-danger px-4" @click="clearUploadTemp(close)">
            <b>取消</b>
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import "cropperjs/dist/cropper.css";
  import Compressor from "compressorjs";
  import ImageCompressor from "image-compressor";
  import Cropper from "cropperjs";


  export default {
    data() {
      return {
        edit: false,
        imageUpload: false,
        imageList: [],
        // imageTemp: [],
        degree: 0,

        uploadTemp: {
          name: "",
          image: "",
          source: this.$store.state.user.Login.name,
          files: []
        },

        path: process.env.VUE_APP_DATA_PATH
      }
    },

    props: {
      pointData: {
        type: Object
      },

      open: {
        type: Boolean
      }
    },

    computed: {
      imageOpen: {
        get() {
          return this.$props.open;
        },

        set(open) {
          this.$emit("update:open", open);
        }
      },

      imageTemp: {
        get() {
          return this.$store.state.schedule.personalList.image;
        }
      },

      getRealyUrl() {
        return (path) => {
          if (path.indexOf("data/") > -1) {
            return this.path + path;
          } else {
            return path;
          }
        }
      },

      state() {
        return (data) => {
          return data.length > 0;
        }
      },

      invalidFeedback() {
        return (data) => {
          return "請輸入資料";
        }
      }
    },

    methods: {
      checkUploadData() {
        this.imageList = [];
        // this.imageTemp = [];
        this.degree = 0;
      },

      checkImageUpload() {
        this.edit = false;
        this.uploadTemp = {
          name: "",
          image: "",
          source: this.$store.state.user.Login.name,
          files: []
        }
      },

      changeRotate(rotate) {
        this.degree = (this.degree + rotate) % 360;
        this.cropper.rotate(rotate);
      },

      editSave() {
        this.edit = false;
        let fileTemp = this.uploadTemp.files[0];

        let binStr = atob(
          this.cropper
          .getCroppedCanvas()
          .toDataURL(fileTemp.type)
          .split(",")[1]
        );
        let arr = new Uint8Array(binStr.length);

        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        let file = new File([arr], fileTemp.name, {
          type: fileTemp.type
        });

        this.$refs.uploadImage.update(fileTemp, {
          file,
          type: file.type,
          size: file.size,
          active: true
        });

        let tempName = file.name;
        let subNameIndex = tempName.lastIndexOf('.');
        if (subNameIndex > -1) {
          tempName = tempName.substr(0, subNameIndex);
        }

        this.uploadTemp.name = tempName;
      },

      editCancel() {
        this.$refs.uploadImage.clear();
      },

      inputFilter(newFile, oldFile, prevent) {
        if (!newFile && !oldFile) {
          if (!/\.(gif|jpg|jpeg|png)$/i.test(newFile.name)) {
            this.$store.dispatch("Public/addAlert", {
              title: "格式錯誤",
              type: "danger",
              msg: "您的照片需符合 gif、jpg、jpeg、png 格式。"
            })
            return prevent();
          }
        }

        if (newFile.file && newFile.type.substr(0, 6) === "image/") {
          newFile.error = "compressing";
          const compressor = new Compressor(newFile.file, {
            quality: 0.5,
            success(file) {
              let URL = window.URL || window.webkitURL;
              newFile.blob = "";
              newFile.thumb = "";

              if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(file);
              }

              if (newFile.blob && file.type.substr(0, 6) === "image/") {
                newFile.thumb = newFile.blob;
              }
            },
          });
        }

        if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
          let URL = window.URL || window.webkitURL;
          newFile.url = "";

          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file);
          }
        }
      },

      inputFile(newFile, oldFile) {
        if (newFile && !oldFile) {
          this.$nextTick(function () {
            this.edit = true;
          });
        }

        if (!newFile && oldFile) {
          this.edit = false;
        }
      },

      uploadSubmit(close) {
        const state = this.checkField(this.uploadTemp, ["name", "source"]);
        if (state) {
          this.imageList.push(this.uploadTemp);
          this.clearUploadTemp(close);
        } else {
          this.$store.dispatch("Public/addAlert", {
            type: "danger",
            title: "輸入確認",
            msg: "請確認欄位是否填寫正確"
          });
        }
      },

      checkField(list, field) {
        let checkNull = [];
        _.forEach(field, (item, key) => {
          if (!list[item]) {
            checkNull.push(item);
          }
        });

        return checkNull.length > 0 ? false : true;
      },

      clearUploadTemp(close) {
        this.uploadTemp = {
          files: [],
          image: "",
          name: "",
          source: ""
        };

        close()
      },

      upload() {
        if (!(this.imageList.length > 0)) {
          this.$store.dispatch("Public/addAlert", {
            title: "錯誤",
            type: "danger",
            msg: "至少需要一筆新增資料"
          });
          return
        }

        let temp = {
          place: this.pointData.place_id,
          data: this.imageList
        }
        // console.log(temp);
        // return
        this.$store.dispatch("schedule/uploadImage", temp).then((data) => {
          this.imageList = [];
          this.$store.dispatch("Public/addAlert", {
            title: "上傳成功",
            type: "success",
            msg: "上傳完成，請移至我的景點照片查看"
          });
        });
      }
    },

    mounted() {
      this.$store.dispatch("schedule/getPersonalImage", this.pointData);
    },

    watch: {
      "edit"(value) {
        if (value) {
          this.$nextTick(function () {
            if (!this.$refs.editImage) {
              return;
            }

            let editImage = new Cropper(this.$refs.editImage, {
              viewMode: 1,
              dragMode: "move",
              cropBoxResizable: false,
              cropBoxMovable: false,
              minCropBoxWidth: 280,
              minCropBoxHeight: 210,
              center: true,
              ready() {
                let container = this.cropper.getContainerData();
                let crop = this.cropper.getCropBoxData();
                let mobileContainerWidth = (container.height * 8) / 5;
                let containerWidth = container.width - 40;
                let cropBox = {
                  width: 300,
                  height: 187.5
                };

                if (mobileContainerWidth < containerWidth) {
                  cropBox.width = mobileContainerWidth * 0.8;
                  cropBox.height = ((mobileContainerWidth * 5) / 8) * 0.8;
                } else {
                  cropBox.width = containerWidth;
                  cropBox.height = (containerWidth * 5) / 8;
                }

                this.cropper.setCropBoxData(cropBox);

                this.cropper.setCropBoxData({
                  top: (container.height - cropBox.height) / 2,
                  left: (container.width - cropBox.width) / 2
                });
              }
            });

            this.cropper = editImage;
          });
        } else {
          if (this.cropper) {
            this.cropper.destroy();
            this.cropper = false;
          }
        }
      }
    }
  }

</script>
